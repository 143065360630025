import React from 'react';
import { MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';

import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('contacts');

const position = [24.43192, 54.43344]

export const Map = React.memo(() => {
    return (
        <div className={ cn() }>
            {/*// @ts-ignore*/ }
            <MapContainer center={ position } zoom={ 20 } scrollWheelZoom={ true }>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                />
                {/*// @ts-ignore*/ }
                <Marker position={ position }>
                    <Tooltip className={cn({mapTooltip:true})} offset={ [0, 0] } opacity={ 1 } permanent>
                        <strong>Poledivas</strong><br/>The View, Al Sa’adah,<br/>Zone 1, Abu Dhabi, UAE
                    </Tooltip>
                </Marker>
            </MapContainer>
        </div>
    );
});
