import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('about');

export const About = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'About' }/></div>
        </div>
    );
});
