import { createCn } from 'bem-react-classname';
import './style.css';
import React from 'react';
import ContactUsWidget from '../../../mindbody/widgets/contact-us';
import ErrorBoundary from '../../../mindbody/error-boundary';

const cn = createCn('contact-us');

export const ContactUs = () => {
    return (
        <div className={ cn() }>
            <div className={ cn('title') }>
                <h2>
                    Contact us
                </h2>
            </div>
            <ErrorBoundary>
                <ContactUsWidget/>
            </ErrorBoundary>
        </div>
    );
};
