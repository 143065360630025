import React, { useEffect, useState } from 'react';
import loadMBody from '../load-mindbody';

const ClassesWidget = () => {
    const [mindBody, setMindBody] = useState(false);

    useEffect(() => {
        loadMBody(() => {
            setMindBody(true);
        });
    }, []);

    //<healcode-widget data-type="class_lists" data-widget-partner="object" data-widget-id="6c708151235" data-widget-version="0" ></healcode-widget>

    return (
        <>
            { mindBody && (
                <healcode-widget
                    data-type="class_lists"
                    data-widget-partner="object"
                    data-widget-id="6c708151235"
                    data-widget-version="0">
                </healcode-widget>
            ) }
        </>
    );
};

ClassesWidget.propTypes = {};

export default ClassesWidget;
