import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import ClassesWidget from '../../mindbody/widgets/classes';
import ErrorBoundary from '../../mindbody/error-boundary';

import './style.css';

const cn = createCn('classes-page');

export const Classes = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'Classes' }/></div>
            <ErrorBoundary>
                <ClassesWidget/>
            </ErrorBoundary>
        </div>
    );
});
