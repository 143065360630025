import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './style.css';

const cn = createCn('title');

type TitleProps = {
    title: string;
};

export const Title: FC<TitleProps> = (props) => {
    const { title } = props;
    return (
        <div className={ cn() }>
            <h1>{ title }</h1>
        </div>
    );
};
