import React from 'react';
import { NavLink } from 'react-router-dom';

import './style.css'

type Props = {
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const Links = React.memo((props: Props) => {
    const { onClick } = props;
    return (
        <>
            <NavLink to={ '/' } onClick={ onClick }>Home</NavLink>
            {/*<NavLink to={ '/about' } onClick={ onClick }>About us</NavLink>*/}
            <NavLink to={ '/schedule' } onClick={ onClick }>Schedule</NavLink>
            {/*<NavLink to={ '/team' } onClick={ onClick }>Team</NavLink>*/}
            <NavLink to={ '/classes' } onClick={ onClick }>Classes</NavLink>
            <NavLink to={ '/prices' } onClick={ onClick }>Prices</NavLink>
            {/*<NavLink to={ '/faq' } onClick={ onClick }>FAQ</NavLink>*/}
            <NavLink to={ '/contacts' } onClick={ onClick }>Contacts</NavLink>
        </>
    );
});
