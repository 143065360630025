import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import ScheduleWidget from '../../mindbody/widgets/schedule';
import ErrorBoundary from '../../mindbody/error-boundary';

import './style.css';

const cn = createCn('schedule');

export const Schedule = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'Schedule' }/></div>
            <ErrorBoundary>
                <ScheduleWidget/>
            </ErrorBoundary>
        </div>
    );
});
