import { createCn } from 'bem-react-classname';
import React from 'react';

import './style.css'

const cn = createCn('whoweare');

export const WhoWeaAre = React.memo(() => {
    return (
        <div className={ cn() }>
            <h2>
                Professional Pole and Aerial Fitness studio
            </h2>
            <div className={ cn('text') }>
                Biggest in the UAE, equipped with 13 professional 42mm stainless steel poles (4m high), 8 aerial hoops
                and 8 aerial silks.

                Pole Divas is a real palace for Pole and Aerial practitioners, based in convenient location with easy
                access and ample parking.
            </div>
        </div>
    );
});
