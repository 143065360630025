import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('team');

export const Team = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'Team' }/></div>
        </div>
    );
});
