import React from 'react';
import { Title } from '../../components/title';
import { Map } from './map';
import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('contacts');

export const Contacts = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'Contacts' }/></div>
            <div className={ cn('map') }>
                <Map/>
            </div>
        </div>
    );
});
