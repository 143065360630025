import React, { useEffect } from 'react';
import { Navbar } from '../navbar';
import { Footer } from '../footer';
import { Copyright } from '../copyright';

const withLayout = (WrappedComponent: any) => {
    return (
        <>
            <Navbar/>
            <div className="content">
                <WrappedComponent/>
            </div>
            <Footer/>
        </>
    );
};

export default withLayout;
