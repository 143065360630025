import React, { useEffect, useState } from 'react';
import loadMBody from '../../mindbody/load-mindbody';
import ErrorBoundary from '../../mindbody/error-boundary';
import { createCn } from 'bem-react-classname';

import './style.css';

const cn = createCn('prices');

type LinkProps = {
    linkId: string, text: string, price: string
}

const PriceLink = React.memo((props: LinkProps) => {
    const [mindBody, setMindBody] = useState(false);
    const { linkId, text, price } = props;

    useEffect(() => {
        loadMBody(() => {
            setMindBody(true);
        });
    }, []);

    return (
        <>
            <div className={ cn({ 'name': true }) }>
                { text }
            </div>
            <div className={ cn({ 'buy': true }) }>
                <span className={cn({'price': true})}>
                    { price }
                </span>
                <ErrorBoundary>
                    { mindBody && (
                        <healcode-widget
                            data-version="0.2"
                            data-link-class="healcode-pricing-option-text-link"
                            data-site-id="113106"
                            data-mb-site-id="5731315"
                            data-bw-identity-site="true"
                            data-type="pricing-link"
                            data-inner-html="Buy Now"
                            data-service-id={ linkId }
                        >
                        </healcode-widget>
                    ) }
                </ErrorBoundary>
            </div>
        </>
    );
});

export default PriceLink;
