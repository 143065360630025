import { createCn } from 'bem-react-classname';
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from './img/logo.png';
import fb from './img/fb.svg';
import tk from './img/tk.svg';
import inst from './img/inst.svg';
import call from './img/call.svg';
import email from './img/email.svg';
import location_on from './img/location_on.svg';
import './style.css'
import { Copyright } from '../copyright';

const cn = createCn('footer');

export const Footer = React.memo(() => {
    return (
        <div className={ cn() }>
            <div className="content">
                <div className={ cn('social') }>
                    <div className={ cn('logo') }>
                        <img src={ logo } alt=""/>
                    </div>
                    <div className={ cn('slogan') }>
                        PoleDivas is a modern Pole Dance
                        school in Abu Dhabi.
                    </div>
                    <div className={ cn('slinks') }>
                        <div className={ cn('item') }>
                            <a href="https://instagram.com/poledivas.ae?igshid=YjNmNGQ3MDY=" className={ cn('link') }
                               target={ '_blank' }>
                                <img src={ inst } alt=""/>
                            </a>
                        </div>
                        <div className={ cn('item') }>
                            <a href="https://www.tiktok.com/@poledivas_?_t=8bRJzgddLrW&_r=1" className={ cn('link') }
                               target={ '_blank' }>
                                <img src={ tk } alt=""/>
                            </a>
                        </div>
                        <div className={ cn('item') }>
                            <a href="https://www.facebook.com/profile.php?id=100091313444891" className={ cn('link') }
                               target={ '_blank' }>
                                <img src={ fb } alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={ cn('contacts') }>
                    <div className={ cn('sub-title') }>
                        Get in Touch
                    </div>
                    <div className={ cn('rows') }>
                        <div className={cn('address')}>
                            <img src={ location_on } alt=""/>
                            The View, Al Sa’adah, Zone 1, Abu Dhabi, UAE
                        </div>
                        <div className={cn('email')}>
                            <img src={ email } alt=""/>
                            <a href="mailto:poledivas@poledivas.ae">poledivas@poledivas.ae</a>
                        </div>
                        <div className={cn('phone')}>
                            <img src={ call } alt=""/>
                            <a href="tel:+971 50 622 80 44">+971 50 622 80 44</a>
                        </div>
                    </div>
                </div>
                <div className={ cn('support') }>
                    <div className={ cn('sub-title') }>
                        Support
                    </div>
                    <div className={ cn('links') }>
                        Terms of service
                        Legal
                        Privacy policy
                    </div>
                </div>
            </div>
            <Copyright/>
        </div>
    );
});
