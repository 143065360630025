import { createCn } from 'bem-react-classname';
import React from 'react';

import './style.css'

const cn = createCn('whychoose');

export const WhyChoose = React.memo(() => {
    return (
        <div className={ cn() }>
            <h2>
                Why Choose Us
            </h2>
            <div className={ cn('items') }>
                <div className={ cn('item') }>
                    Professional, state of the art pole and aerial equipment (unique in Abu Dhabi)
                </div>
                <div className={ cn('item') }>
                    Fully certified, highly experience and friendly instructors
                </div>
                <div className={ cn('item') }>
                    High-end, light and modern premises
                </div>
            </div>
            <div className={ cn('sub') }>
                Your safety is our priority, we ensure that your workouts are fun, effective and injury-free, therefore
                we invest heavily in the quality of our equipment and professionalism of our instructors
            </div>
        </div>
    );
});
