import React, { useEffect, useState } from 'react';
import loadMBody from '../load-mindbody';

const ContactUsWidget = () => {
    const [mindBody, setMindBody] = useState(false);

    useEffect(() => {
        loadMBody(() => {
            setMindBody(true);
        });
    }, []);

    //<healcode-widget
    //                 data-type="prospects"
    //                 data-widget-partner="object"
    //                 data-widget-id="6c460311235"
    //                 data-widget-version="0">
    //             </healcode-widget>

    return (
        <>
            { mindBody && (
                <healcode-widget
                    data-type="prospects"
                    data-widget-partner="object"
                    data-widget-id="6c460311235"
                    data-widget-version="0">
                </healcode-widget>
            ) }
        </>
    );
};

ContactUsWidget.propTypes = {};

export default ContactUsWidget;
