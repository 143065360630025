import { createCn } from 'bem-react-classname';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from './img/logo.png';
import call from './img/call.svg';
import './style.css'
import { Links } from './links';

const cn = createCn('navbar');

export const Navbar = React.memo(() => {
    const [burgerVisible, setBurgerVisible] = useState(false);
    const handleBurgerClick = useCallback(() => {
        setBurgerVisible(!burgerVisible);
    }, [burgerVisible]);

    const menuRef = useRef(null);

    const closeBurgerModal = useCallback(() => {
        setBurgerVisible(false);
    }, []);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // @ts-ignore
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setBurgerVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [burgerVisible]);

    return (
        <div className={ cn() }>
            <div className="content">
                <div className={ cn('logo') }>
                    <a href="/">
                        <img src={ logo } alt=""/>
                    </a>
                </div>
                <div className={ cn('links') }>
                    <Links/>
                </div>
                <div className={ cn('phone') }>
                    <img src={ call } alt=""/>
                    <div><a href="tel:+971 50 622 80 44">+971 50 622 80 44</a></div>
                </div>
                {/*<div className={ cn('book') }>*/}
                {/*    <a href="#">Book your trial</a>*/}
                {/*</div>*/}
                <div className={ cn('mobile-menu') } ref={ menuRef }>
                    <button className={ cn('burger') } onClick={ handleBurgerClick }/>
                    <div className={ cn('mlinks', { visible: burgerVisible }) }>
                        <Links onClick={ closeBurgerModal }/>
                    </div>
                </div>
            </div>
        </div>
    );
});
