import React, { useEffect, useState } from 'react';
import loadMBody from '../load-mindbody';

const ScheduleWidget = () => {
    const [mindBody, setMindBody] = useState(false);

    useEffect(() => {
        loadMBody(() => {
            setMindBody(true);
        });
    }, []);

    //<healcode-widget
    //                 data-type="schedules"
    //                 data-widget-partner="object"
    //                 data-widget-id="6c1922851235"
    //                 data-widget-version="1" >
    //             </healcode-widget>

    return (
        <>
            { mindBody && (
                <healcode-widget
                    data-type="schedules"
                    data-widget-partner="object"
                    data-widget-id="6c1922851235"
                    data-widget-version="1">
                </healcode-widget>
            ) }
        </>
    );
};

ScheduleWidget.propTypes = {};

export default ScheduleWidget;
