import React from 'react';
import { Route, Routes } from 'react-router-dom';
import withLayout from './components/with-layout';
import { About } from './routes/about';
import { Faq } from './routes/faq';
import { Home } from './routes/home';
import { Team } from './routes/team';
import { Classes } from './routes/classes';
import { Prices } from './routes/prices';
import { Contacts } from './routes/contacts';
import { Schedule } from './routes/schedule';

import './App.css';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={ withLayout(Home) }/>
                <Route path="/about" element={ withLayout(About) }/>
                <Route path="/schedule" element={ withLayout(Schedule) }/>
                <Route path="/team" element={ withLayout(Team) }/>
                <Route path="/classes" element={ withLayout(Classes) }/>
                <Route path="/prices" element={ withLayout(Prices) }/>
                <Route path="/faq" element={ withLayout(Faq) }/>
                <Route path="/contacts" element={ withLayout(Contacts) }/>
            </Routes>
        </div>
    );
}

export default App;
