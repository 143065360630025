import React from 'react';
import { Title } from '../../components/title';
import { createCn } from 'bem-react-classname';
import PriceLink from './link';

import './style.css';

const cn = createCn('prices');

export const Prices = React.memo(() => {
    return (
        <div className={ cn() }>
            <div><Title title={ 'Prices' }/></div>
            <div className={ cn('content') }>
                <div className={ cn({'pane': true}) }>
                    <div className={ cn({ 'title': true }) }>
                        Group Classes
                    </div>
                    <div className={ cn({ 'content': true }) }>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                price={ 'AED 95.00' }
                                text={ 'Trial class (any discipline)' }
                                linkId={ '101113' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Single Class' }
                                price={ 'AED 135.00' }
                                linkId={ '100002' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Star Diva Light - 4 classes - 1 month valid' }
                                price={ 'AED 483.00' }
                                linkId={ '101114' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Star Diva Light Plus - 8 classes - 1 month valid' }
                                price={ 'AED 924.00' }
                                linkId={ '101115' }
                            />
                        </div>

                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Sky Diva - 10 classes - 5 months valid' }
                                price={ 'AED 1,197.00' }
                                linkId={ '101117' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Star Diva Regular - 12 classes - 1 month valid' }
                                price={ 'AED 1,323.00' }
                                linkId={ '101116' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Star Diva Power - 16 classes - 2 months valid' }
                                price={ 'AED 1,680.00' }
                                linkId={ '101118' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Star Diva - 20 classes - 2,5 months valid' }
                                price={ 'AED 1,995.00' }
                                linkId={ '101119' }
                            />
                        </div>
                    </div>
                </div>
                <div className={ cn({ 'pane': true }) }>
                    <div className={ cn({ 'title': true }) }>
                        Unlimited Packages
                    </div>
                    <div className={ cn({ 'content': true }) }>
                        <div className={ cn({ 'row': true }) }>
                        <PriceLink
                                text={ 'Silver Diva - Unlimited 1 month package' }
                                price={ 'AED 2,099.00' }
                                linkId={ '100025' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Golden Diva - Unlimited 3 months package' }
                                price={ 'AED 5,985.00' }
                                linkId={ '100010' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Platinum Diva - Unlimited 6 months package' }
                                price={ 'AED 9,450.00' }
                                linkId={ '100011' }
                            />
                        </div>
                        <div className={ cn({ 'row': true }) }>
                            <PriceLink
                                text={ 'Diamond Diva - Unlimited 1 Year Package' }
                                price={ 'AED 15,794.00' }
                                linkId={ '100026' }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
