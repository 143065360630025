import { createCn } from 'bem-react-classname';
import React from 'react';

import './style.css'

const cn = createCn('copyright');

const YEAR = new Date().getFullYear();

export const Copyright = React.memo(() => {
    return (
        <div className={ cn() }>
            <div className="content">
                ©{ YEAR } PoleDivas. All rights reserved
            </div>
        </div>
    );
});
