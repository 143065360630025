import React from 'react';
import { createCn } from 'bem-react-classname';
import { Slider } from './slider';
import { WhoWeaAre } from './whoweare';
import { WhyChoose } from './whychoose';
import { ContactUs } from './contact-us';
import { Classes } from './classes';
import { Packages } from './packages';
import './style.css';

const cn = createCn('home');

//<Slider/>

export const Home = React.memo(() => {
    return (
        <div className={ cn() }>
            <div className={ cn('content') }>
                <WhoWeaAre/>
                <WhyChoose/>
                {/*<Classes/>*/}
                {/*<Packages/>*/}
                <ContactUs/>
            </div>
        </div>
    );
});
